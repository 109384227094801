<template>
	<div class="wrap wrap--login">
		<!-- 헤더 -->
		<main-header></main-header>

		<!-- 메인 -->
		<main class="main" role="main">
			<div class="inner">
                <h2 class="login-title"><span>{{$t('common.로그인')}}</span></h2>

				<div class="login-container">
					<div class="login-rowgroup">
						<div class="login-row">
							<input class="input" type="text" v-model="username" placeholder="아이디">
						</div>
						<div class="login-row">
							<input class="input" type="password" v-model="password" placeholder="비밀번호" @keydown.enter="login">
						</div>
						<div class="login-row login-row--links">
							<label class="check">
								<input v-model="remember" class="input" type="checkbox" :value="true">
								<i class="icon"></i>
								<span class="text">{{$t('common.아이디 저장')}}</span>
							</label>
							<ul class="links">
								<li><a class="link" href="/login/find-id/">{{$t('common.아이디 찾기')}}</a></li>
								<li><a class="link" href="/login/find-password/">{{$t('common.비밀번호 찾기')}}</a></li>
							</ul>
						</div>
						<div class="login-row login-row--submit">
							<button class="button button--primary" type="button" @click="login"><span>{{$t('common.로그인')}}</span></button>
						</div>
					</div>
					<!-- 회원가입/소셜버튼 -->
					<!-- <div class="login-rowgroup login-rowgroup--buttons">
						<div class="login-row">
							<a class="button button--border" href="/join/type"><span>회원가입</span></a>
						</div>
						<div class="login-row">
							<kakao-login client_id="53ce3af6a7f73f20faa30fef193adcfb"></kakao-login>
						</div>
					</div> -->
				</div>
			</div>
		</main>

		<!-- 푸터 -->
		<main-footer></main-footer>
	</div>
</template>

<script>
import CryptoAES from '@/plugins/crypto-aes';

import MainHeader from "@/components/client/inc/main-header.vue";
import MainFooter from "@/components/client/inc/main-footer.vue";

import KakaoLogin from "@/components/plugins/kakao/kakao-login.vue";

export default{
	components: {
		MainHeader,
		MainFooter,
        KakaoLogin
	},
	data(){
		return {
			username: "",
			password: "",

			remember: false
		}
	},
	mounted() {
		this.init();
	},
	methods : {
		init : function() {
			if(this.$cookies.get('username')) this.username = this.$cookies.get('username');
			// if(this.$cookies.get('password')) this.password = CryptoAES.decrypt(this.$cookies.get('password'));
		},
		async login(){
			try{
				await this.$store.dispatch("login", { username: this.username, password: this.password, remember: this.remember });

				if(this.remember){
					this.$cookies.set('username', this.username);
					this.$cookies.set('password', CryptoAES.encrypt(this.password));
				}

				this.$router.push('/');
			}
			catch(error){
				console.error(error);
				if(error.response) alert(error.response.data.message);
				else alert(error.message);
			}
		},
	},
}
</script>
