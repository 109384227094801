var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap wrap--login"
  }, [_c('main-header'), _c('main', {
    staticClass: "main",
    attrs: {
      "role": "main"
    }
  }, [_c('div', {
    staticClass: "inner"
  }, [_vm._m(0), _c('div', {
    staticClass: "login-container"
  }, [_c('div', {
    staticClass: "login-rowgroup"
  }, [_c('div', {
    staticClass: "login-row login"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.username,
      expression: "username"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "name": "",
      "value": "",
      "placeholder": "아이디"
    },
    domProps: {
      "value": _vm.username
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.username = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "login-row login-row--button"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.email,
      expression: "email"
    }],
    staticClass: "input",
    attrs: {
      "type": "email",
      "name": "",
      "value": "",
      "placeholder": "이메일"
    },
    domProps: {
      "value": _vm.email
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.email = $event.target.value;
      }
    }
  }), _c('button', {
    staticClass: "button button--primary-dark",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": _vm.certify
    }
  }, [_c('span', [_vm._v("인증번호전송")])])]), _c('div', {
    staticClass: "login-row"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.otp,
      expression: "otp"
    }],
    staticClass: "input",
    attrs: {
      "type": "number",
      "placeholder": "인증번호 입력"
    },
    domProps: {
      "value": _vm.otp
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.otp = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "login-row login-row--submit"
  }, [_c('button', {
    staticClass: "button button--primary",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": _vm.recovery
    }
  }, [_c('span', [_vm._v("확인")])])])])])])]), _c('main-footer')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', {
    staticClass: "login-title"
  }, [_c('span', [_vm._v("비밀번호 찾기")])]);

}]

export { render, staticRenderFns }