var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap wrap--login"
  }, [_c('main-header'), _c('main', {
    staticClass: "main",
    attrs: {
      "role": "main"
    }
  }, [_c('div', {
    staticClass: "inner"
  }, [_c('h2', {
    staticClass: "login-title"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('common.로그인')))])]), _c('div', {
    staticClass: "login-container"
  }, [_c('div', {
    staticClass: "login-rowgroup"
  }, [_c('div', {
    staticClass: "login-row"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.username,
      expression: "username"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "placeholder": "아이디"
    },
    domProps: {
      "value": _vm.username
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.username = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "login-row"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.password,
      expression: "password"
    }],
    staticClass: "input",
    attrs: {
      "type": "password",
      "placeholder": "비밀번호"
    },
    domProps: {
      "value": _vm.password
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.login.apply(null, arguments);
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.password = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "login-row login-row--links"
  }, [_c('label', {
    staticClass: "check"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.remember,
      expression: "remember"
    }],
    staticClass: "input",
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "value": true,
      "checked": Array.isArray(_vm.remember) ? _vm._i(_vm.remember, true) > -1 : _vm.remember
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.remember,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = true,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.remember = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.remember = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.remember = $$c;
        }
      }
    }
  }), _c('i', {
    staticClass: "icon"
  }), _c('span', {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.$t('common.아이디 저장')))])]), _c('ul', {
    staticClass: "links"
  }, [_c('li', [_c('a', {
    staticClass: "link",
    attrs: {
      "href": "/login/find-id/"
    }
  }, [_vm._v(_vm._s(_vm.$t('common.아이디 찾기')))])]), _c('li', [_c('a', {
    staticClass: "link",
    attrs: {
      "href": "/login/find-password/"
    }
  }, [_vm._v(_vm._s(_vm.$t('common.비밀번호 찾기')))])])])]), _c('div', {
    staticClass: "login-row login-row--submit"
  }, [_c('button', {
    staticClass: "button button--primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.login
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('common.로그인')))])])])])])])]), _c('main-footer')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }