<template>
	<div class="wrap wrap--login">
		<!-- 헤더 -->
		<main-header></main-header>

		<!-- 메인 -->
		<main class="main" role="main">
			<div class="inner">
                <h2 class="login-title"><span>비밀번호 찾기</span></h2>

				<div class="login-container">
					<div class="login-rowgroup">
						<!-- <div class="login-row login-row--button">
							<input v-model="username" class="input" type="email" name="" value="" placeholder="이메일">
						</div> -->
						<div class="login-row login">
							<input v-model="username" class="input" type="text" name="" value="" placeholder="아이디">
						</div>
						<div class="login-row login-row--button">
							<!-- <div class="select-chip">
								<select v-model="phone1" class="select">
									<option value="010">010</option>
									<option value="011">011</option>
									<option value="016">016</option>
									<option value="017">017</option>
									<option value="019">019</option>
								</select>
								<input v-model="phone2" class="input" type="number" placeholder="- 없이 입력">
							</div> -->
							<input v-model="email" class="input" type="email" name="" value="" placeholder="이메일">
							<button class="button button--primary-dark" type="submit" @click="certify"><span>인증번호전송</span></button>
						</div>
						<div class="login-row">
							<input v-model="otp" class="input" type="number" placeholder="인증번호 입력">
						</div>
						<div class="login-row login-row--submit">
							<button class="button button--primary" type="submit" @click="recovery"><span>확인</span></button>
						</div>
					</div>
				</div>
			</div>
		</main>

		<!-- 푸터 -->
		<main-footer></main-footer>
	</div>
</template>

<script>
import api from "@/api";
import MainHeader from "../../../components/client/inc/main-header.vue";
import MainFooter from "../../../components/client/inc/main-footer.vue";

export default {
	components: {
		MainHeader,
		MainFooter
	},
	data() {
		return {
			username: null,
			email: null,
			otp: null
		}
	},
	methods: {

		validate(recovery = false){
			try{
				if(!this.username) throw new Error("아이디를 입력해주세요");
				if(!this.email) throw new Error("이메일을 입력해주세요");
				if(recovery && !this.otp) throw new Error("인증번호를 입력해주세요");

				return true;
			}
			catch(error){
				alert(error.message);
			}
			return false;
		},

		async certify(){
			if(this.validate()){

				await api.v1.users.certify.post({
					type: "email",
					kind: "password",
					username: this.username,
					email: this.email
				});

				alert("인증번호가 전송되었습니다");
			}
		},

		async recovery(){
			if(this.validate(true)){
				await api.v1.users.recovery.post({
					type: "email",
					kind: "password",
					email: this.email,
					username: this.username,
					otp: this.otp
				});
	
				alert("가입한 이메일 정보로 비밀번호가 발송되었습니다");
			}
		},
	},
}
</script>
